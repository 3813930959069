<template>
  <div style="width: fit-content;">
    <!-- <div v-for="stream in streamPortList" :key="stream.port" style="width: 100%;">
      <h3>{{`video-canvas${stream.port}`}}</h3>
      <canvas :id="`video-canvas${stream.port}`" style="width: 100%;" @click="stopPlayer(stream.port)"></canvas>
    </div> -->
    <!-- <canvas id="video-canvas9998" style="width: 30%;" @click="stopPlayer()"></canvas>
    <canvas id="video-canvas9996" style="width: 30%;"></canvas>
    <canvas id="video-canvas9994" style="width: 30%;"></canvas>
    <canvas id="video-canvas9992" style="width: 30%;"></canvas>
    <canvas id="video-canvas9990" style="width: 30%;"></canvas>
    <canvas id="video-canvas9988" style="width: 30%;"></canvas> -->
    <canvas id="video-canvas1" @click="detailShowCCTV()"></canvas>
  </div>
</template>
<script>

import JSMpeg from 'jsmpeg-player'
import axios from 'axios'

export default {
  data: () => {
      return {
          streamingServerPort: 4001,
          /* player1: null,
          player2: null,
          player3: null,
          player4: null,
          player5: null,
          player6: null, */
          playerList: [],
          streamPortList: [],
          requestList: []
      }
  },
  mounted() {
    
  }, 
  beforeDestroy() {
    /* for(let [idx, stream] of this.streamPortList.entries()) {
      if(this[`player${idx + 1}`] !== null) axios.get(`https://rtsp.e-farm.site/?port=${4001}/stop/${stream.port}`)
    } */
    for(let player of this.playerList) {
      console.log('request stop stream port', player.port)
      axios.get(`https://rtsp.e-farm.site/?port=${4001}/stop/${player.port}`)
    }
    
  },
  destroyed() {
   
  },
  created() {
    //this.initPlayers()
  },
  methods: {
    async initPlayers() {
      this.requestList.push({
        _id: 9999,
        rtspUrl: `rtsp://admin:12345@192.168.0.205:554/media/video2`//`rtsp://admin:12345@192.168.0.205:554/media/video1`
      })
      this.requestList.push({
        _id: 9997,
        rtspUrl: `rtsp://admin:ewha2110@e-farm0001.iptime.org:554/media/video2`//`rtsp://admin:12345@192.168.0.205:554/media/video1`
      })
      this.requestList.push({
        _id: 9995,
        rtspUrl: `rtsp://admin:ewha2110@e1036990018.iptime.org:554/media/video2`//`rtsp://admin:12345@192.168.0.205:554/media/video1`
      })
      this.requestList.push({
        _id: 9993,
        rtspUrl: `rtsp://admin:ewha2110@e1036990018.iptime.org:555/media/video2`//`rtsp://admin:12345@192.168.0.205:554/media/video1`
      })
      this.requestList.push({
        _id: 9991,
        rtspUrl: `rtsp://admin:ewha2110@h01040309615.iptime.org:555/media/video2`//`rtsp://admin:12345@192.168.0.205:554/media/video1`
      })
      this.requestList.push({
        _id: 9989,
        rtspUrl: `rtsp://admin:ewha2110@h01040309615.iptime.org:556/media/video2`//`rtsp://admin:12345@192.168.0.205:554/media/video1`
      })
      for(let stream of this.requestList) await this.initPlayer(stream)
    },
    async initPlayer(data) {
        //const streamingServerPort = 4001
        
        let res = await axios.post(`https://rtsp.e-farm.site/?port=${4001}/start`, data)
        let streamPort
        if(res.data.result) streamPort = res.data.port
        else return false
        this.streamPortList.push({port: streamPort})
        console.log('request', `wss://rtsp.e-farm.site/?port=${streamPort}`)
        setTimeout(async () => {
          let vPlayer = new JSMpeg.Player(`wss://rtsp.e-farm.site/?port=${streamPort}`, {
            canvas: document.getElementById(`video-canvas${streamPort}`),
            loop: false,
            autoplay: true,
          })
          vPlayer.progressive = true
          this.playerList.push({port: streamPort, vPlayer})
          return true
        }, 150 * this.streamPortList.length);
      
    },  
    stopPlayer(port) {
      let data = this.playerList.find(e => e.port == port) 
      if(data.vPlayer.paused) data.vPlayer.play() 
      else {
        data.vPlayer.pause()
        //this.toggleFullScreen()
      }
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen()
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      }
    }

  }
}
</script>
<style lang="" scoped>

</style>